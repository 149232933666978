<template>
  <a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
    <section class="hero is-fullheight">
      <div class="hero-body">
        <div class="container">
          <div class="column multiline">
            <div class="col-12">
              <div class="card">
                <div class="card-content">
                  <div class="columns is multiline">
                    <div class="column is-8">
                      <p class="paragraph"><b>PRECIO DE DÓLAR</b></p>
                      <p class="text">Costo de 1 dólar en pesos mexicanos.</p>
                      <p class="text pt-5" v-if="!editarDolar">{{ hasDollarCost ? `$${numeral(currentDollarValue.cost_dollar).format('0.00')} MXN` : 'Sin información' }}</p>
                      <div class="column is-6 pt-5" v-show="editarDolar">
                        <div class="is-flex is-justify-content-flex-start is-align-items-center">
                          <input class="input" type="text" placeholder="$" v-model="cost_dollar" v-money="money" />
                          <span class="ml-2"> MXN </span>
                        </div>
                        <div class="buttons pt-5">
                          <button class="button button_micro_dark_outlined" @click="onCancel">Cancelar</button>
                          <button class="button button_micro_dark" @click="onUpdate">Guardar</button>
                        </div>
                      </div>
                    </div>
                    <div class="column is-4 has-text-right">
                      <button id="botonAct" class="button is-black" v-if="!editarDolar" @click="onEdit">Actualizar</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </a-spin>
</template>

<script>
import { mapActions } from 'vuex'
import { VMoney } from 'v-money'
import Swal from 'sweetalert2'
import utilities from '@/extras/utilities'
import numeral from 'numeral'

export default {
  name: 'DollarMain',
  directives: {
    money: VMoney
  },
  computed: {
    currentDollarValue () {
      return utilities.objectValidate(this.$store, 'state.catalogs.currentDollarValue', {
        cost_dollar: ''
      })
    },
    spinnerLoader () {
      return this.$store.state.catalogs.spinnerLoader
    },
    spinnerLoaderLabel () {
      return this.$store.state.catalogs.spinnerLoaderLabel
    },
    hasDollarCost () {
      return !!utilities.objectValidate(this.currentDollarValue, 'cost_dollar', false)
    }
  },
  data () {
    return {
      editarDolar: false,
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$',
        suffix: '',
        precision: 2,
        masked: false
      },
      cost_dollar: 0
    }
  },
  mounted () {
    if (!utilities.objectValidate(this.currentDollarValue, 'cost_dollar', false)) {
      this.getCurrentDollarValue()
    }
  },
  methods: {
    ...mapActions(['getCurrentDollarValue', 'updateCurrentDollarValue']),
    numeral,
    onEdit () {
      this.cost_dollar = numeral(this.currentDollarValue.cost_dollar).format('0.00')
      this.editarDolar = true
    },
    onCancel () {
      this.editarDolar = false
      this.cost_dollar = 0
    },
    onUpdate () {
      Swal.fire({
        title: '¡Atención!',
        text: 'Estás a punto de actualizar el registro. ¿Deseas continuar?',
        showCancelButton: true,
        confirmButtonText: 'Sí, continuar',
        cancelButtonText: 'Cancelar',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'button button_micro_dark',
          cancelButton: 'button button_micro_dark_outlined'
        },
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          const costDollar = numeral(this.cost_dollar).value()
          this.updateCurrentDollarValue(costDollar)
        }
      })
    }
  },
  watch: {
    currentDollarValue () {
      this.editarDolar = false
    }
  }
}
</script>

<style lang="scss" scoped>
.text {
  font-family: Roboto;
  font-size: 16px;
  font-style: italic;
  line-height: 1.1;
  text-align: left;
  color: #262626;
}
.card {
  border-radius: 18px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.11);
  background-color: #fff;
}
.card-content {
  padding: 44.5px 66px 83.5px 120px;
}
#botonAct {
  width: 183px;
  height: 42px;
}
.input {
  width: 333px;
  height: 48px;
}
</style>
